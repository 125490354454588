import React, {useState, useEffect} from 'react'
import Table from '../../../components/data/Table'
import DownloadAanmeldformulier from '../../../components/data/aanmeldformulier/DownloadAanmeldformulier'
import axios from 'axios'
import LoadingIcon from '../../../components/layout/LoadingIcon'
import { dashboardColumns } from './utils/dashboardColumns'
import { DataTable } from '../../../components/data/datatable/DataTable'

const DEV_API_URL = 'http://localhost:3001'
const PROD_API_URL = 'https://s51vi4jzw8.execute-api.us-east-1.amazonaws.com'
const HEADERS = ['My ID', 'Name', 'Number of products', 'Number of variants', 'Number of forms']
const BODY_ITEMS = [[10, 'Jason B.V.', 100, 2000, 30]]

function Algemeen({data, fetchingData, fetchError}) {
    const [bodyItems, setBodyItems] = useState([])
    const [headers, setHeaders] = useState([])
        
    const initializeBodyItems = (itemArray) => {
        return Object.values(itemArray).map((formData, index) => {
            console.log(formData)
            if(index === 3) return (Date.parse(formData)).toLocaleString('nl-NL')
            return formData
        })
    }
    //'border-2 border-tumfur-main p-2 bg-tumfur-green bg-opacity-70'
    const tableClassNames = {
        table: '',
        tableBody: '',
        tableCell: '',
        tableHead: 'text-white',
        tableHeader: 'p-2 bg-tumfur-green',
        tableRow: '',
    }
        
    return (
        <>
            {headers && data && <DataTable columns={dashboardColumns} data={data} classNames={tableClassNames} searchKey='ClientName'/>}
            {fetchingData && <LoadingIcon text='Data aan het laden ...'/>}
            {fetchError && <p>Data ophalen mislukt ...</p>}
        </>
    )
}

export default Algemeen