import { useState } from "react"
import HeaderDropdown from "./HeaderDropdown"
import headerBodyData from './body-data/headerBodyData'
import tumfurLogo from '../../assets/tumfur-logo.png'
import menuConfig from "./body-data/menuConfig"
import { useLocation } from 'react-router-dom'

function Header({signOut}) {
    const [help, setHelp] = useState()
    const [showSubItems, setShowSubItems] = useState(false)
    const [hoveredMenuItem, setHoveredMenuItem] = useState('')
    const helpIcon = null
    const {menuItems} = menuConfig
    const {pathname} = useLocation()
    console.log(pathname)
    return (
        <header className={`flex w-full h-24 bg-tumfur-main justify-between items-center ${help ? 'opacity-60' : ''}`}>
            <div className='flex flex-row'>
                <a href='/'>
                    <img src={tumfurLogo} className='h-16 ml-4 bg-white rounded-md px-2'></img>
                </a>
                {menuConfig && Object.keys(menuItems).map((dataKey, index) => {
                    const {title, subItems} = menuItems[dataKey]
                    return (
                        <div 
                            key={index} 
                            className='text-white w-32 flex items-center justify-center transition-transform ease-in-out duration-500'
                            onMouseEnter={() => {
                                setHoveredMenuItem(dataKey)
                                setShowSubItems(true)
                            }}
                        >
                            <p className={`text-center text-lg hover:text-tumfur-green ${pathname.split('/')[1] === dataKey ? 'text-tumfur-green font-semibold' : ''}`}>{title}</p>
                            {showSubItems && dataKey === hoveredMenuItem && (
                                <div 
                                    className='absolute top-0 left-0 z-10 flex flex-col gap-y-2 h-fit py-4 px-2 w-screen bg-white text-black border-b-2 border-tumfur-main translate-y-24'
                                    onMouseLeave={() => {
                                        setHoveredMenuItem('')
                                        setShowSubItems(false)
                                    }}
                                >
                                    {subItems.map((subItem, index) => {
                                        return (
                                            <a 
                                                key={index} 
                                                className="text-black hover:text-tumfur-green" 
                                                href={subItem.url}
                                            >
                                                {subItem.title}
                                            </a>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
            <span className='flex flex-row items-center'>
                <HeaderDropdown signOut={signOut}/>
                <button onClick={() => setHelp(!help)}>
                    <img src={helpIcon} className='h-10 mr-4 '></img>
                </button>
            </span>
        </header>
    )
}

export default Header