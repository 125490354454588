import { useAuthenticator, useTheme, Button, View, Image, Text, Heading } from "@aws-amplify/ui-react";
import TumfurLogo from './assets/tumfur-logo.png' 

export const AuthConfig = {
    components: {
        Header() {
            const { tokens } = useTheme();

            return (
            <View textAlign="center" padding={tokens.space.large} className='bg-white mb-4 rounded-lg'>
                <Image
                alt="TUMFUR logo"
                src={TumfurLogo}
                />
            </View>
            );
        },

        Footer() {
            const { tokens } = useTheme();

            return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                &copy; All Rights Reserved
                </Text>
            </View>
            );
        },

        SignIn: {
            Header() {
            const { tokens } = useTheme();

            return (
                <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
                >
                Sign in to your account
                </Heading>
            );
            },
            Footer() {
            const { toResetPassword } = useAuthenticator();

            return (
                <View textAlign="center">
                <Button
                    fontWeight="normal"
                    onClick={toResetPassword}
                    size="small"
                    variation="link"
                >
                    Reset Password
                </Button>
                </View>
            );
            },
        },
        SetupTOTP: {
            Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
                >
                Enter Information:
                </Heading>
            );
            },
            Footer() {
            return <Text>Footer Information</Text>;
            },
        },
        ConfirmSignIn: {
            Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
                >
                Enter Information:
                </Heading>
            );
            },
            Footer() {
            return <Text>Footer Information</Text>;
            },
        },
        ResetPassword: {
            Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
                >
                Enter Information:
                </Heading>
            );
            },
            Footer() {
            return <Text>Footer Information</Text>;
            },
        },
        ConfirmResetPassword: {
            Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
                >
                Enter Information:
                </Heading>
            );
            },
            Footer() {
            return <Text>Footer Information</Text>;
            },
        },
    },

    formFields: {
        signIn: {
            username: {
            placeholder: 'Enter your username',
            },
        },
        forceNewPassword: {
            password: {
            placeholder: 'Enter your password:',
            },
        },
        resetPassword: {
            username: {
            placeholder: 'Enter your username:',
            },
        },
        confirmResetPassword: {
            confirmation_code: {
            placeholder: 'Enter your Confirmation Code:',
            label: 'New Label',
            isRequired: false,
            },
            confirm_password: {
            placeholder: 'Enter your Password Please:',
            },
        },
        setupTOTP: {
            QR: {
            totpIssuer: 'test issuer',
            totpUsername: 'amplify_qr_test_user',
            },
            confirmation_code: {
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
            },
        },
        confirmSignIn: {
            confirmation_code: {
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
            },
        },
    }
}