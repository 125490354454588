import React from 'react'

function MenuItem({name, extraStyle, onClick}) {
    return (
        <div className={`py-4 px-8 ${extraStyle}`}>
            <button onClick={onClick}>{name}</button>
        </div>
    )
}

export default MenuItem