import React from 'react'

export default function Card({children, header, extraStyle}) {
  return (
    <div className={`w-full h-full rounded-md bg-white p-6 shadow-lg ${extraStyle}`}>
        <p className='italic text-lg mb-4 font-semibold'>{header}</p>
        {children}
    </div>
  )
}
