import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import LoadingIcon from '../../layout/LoadingIcon'

const DEV_API_URL = 'http://localhost:3001'
const PROD_API_URL = 'https://s51vi4jzw8.execute-api.us-east-1.amazonaws.com'

function DownloadAanmeldformulier({clientName, formId, filename, buttonText}) {
    const [downloading, setDownloading] = useState(false)
    const [downloadSuccess, setDownloadSuccess] = useState(-1)
    const [downloadUrl, setDownloadUrl] = useState('')

    const navigate = useNavigate()
    
    useEffect(() => {
    }, [])  
    
    const handleDownload = async () => {
        setDownloading(true)
        try{
            axios.post(`${PROD_API_URL}/aanmeldformulier/forms/download-form`, {formData: {clientName: clientName, formId: formId, fileName: filename}, responseType: 'arraybuffer'})
                .then(async (response) => {                    
                    const link = document.createElement('a');
                    link.href = response.data.downloadUrl;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);

                    setDownloading(false)
                })
        } catch(err) {
            setDownloading(false)
            setDownloadSuccess(false)
        }
    }

    return (
        <div className='w-full'>
            <button 
                onClick={handleDownload}
                className='bg-tumfur-green rounded-md text-white p-2 border-2 shadow-lg hover:outline-none hover border-tumfur-main hover:shadow-xl hover:rounded-lg'
                >
                    {downloading ? <LoadingIcon/> : buttonText}
            </button>
        </div>
    )
}

export default DownloadAanmeldformulier