import React, { useState } from 'react'
import axios from 'axios'
import LoadingIcon from '../../layout/LoadingIcon'

function IsCompletedButton({isCompleted, formId}) {
    const [updatingStatus, setUpdatingStatus] = useState(false)
    const PROD_API_URL = 'https://s51vi4jzw8.execute-api.us-east-1.amazonaws.com'

    const handleCompleteSave = async () => {
        try {
            setUpdatingStatus(true)
            const res = axios.get(PROD_API_URL + `/aanmeldformulier/forms/update-iscomplete/${formId}/${isCompleted === 1 ? 0 : 1}`).then((res) => {
                console.log(res)
                setUpdatingStatus(false)
                window.location.reload()
                return res
            })
        } catch(err) {
            console.log(err)
        }
    }
    return (
        <div className='flex w-full gap-x-2 justify-center'>
            {
                updatingStatus ?
                    <LoadingIcon />
                    : 
                    <button
                        disabled={isCompleted > 0}
                        className={`mx-auto h-full rounded-lg bg-tumfur-green text-white px-2 border-2 border-tumfur-main shadow-lg hover:opacity-80 ${isCompleted > 0 ? 'bg-tumfur-green' : 'bg-red-400'}`}
                        onClick={handleCompleteSave}>
                        {isCompleted > 0 ? 'INGEBOEKT' : 'Inboeken'}
                    </button>
            }
        </div>      
    )
}

export default IsCompletedButton