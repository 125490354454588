import DownloadAanmeldformulier from "../../../../components/data/aanmeldformulier/DownloadAanmeldformulier"
import IsCompletedButton from "../../../../components/data/aanmeldformulier/IsCompletedButton"

export const dashboardColumns= [
    {
        accessorKey: "Reference",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Referentie
                </button>
            )
        },
    },
    {
        accessorKey: "ClientId",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Klant ID
                </button>
            )
        },
    },
    {
        accessorKey: "Name",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Klant Naam
                </button>
            )
        },
    },
    {
        accessorKey: "FormId",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Formulier ID
                </button>
            )
        },
    },
    {
        accessorKey: "Date",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Datum
                </button>
            )
        },
    },
    {
        accessorKey: "Excel",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Download aanmeldformulier
                </button>
            )
        },
        cell: (object, _unused) => {
            const {Name, FormId} = object.row.original
            return (
                <DownloadAanmeldformulier clientName={Name} formId={FormId} filename={`${Name.replace(/ /g, '_').toLowerCase()}_${FormId}.xlsx`} buttonText='Download aanmeldformulier'/>
                )
        },
    },
    {
        accessorKey: "ExcelUpload",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                    Download upload bestand
                </button>
            )
        },
        cell: (object, _unused) => {
            const {Name, FormId} = object.row.original
            return (
                <DownloadAanmeldformulier clientName={Name} formId={FormId} filename={`${Name.replace(/ /g, '_').toLowerCase()}_${FormId}_UPLOAD.xlsx`} buttonText='Download upload file'/>
                // <></>
            )
        },
    },
    {
        accessorKey: "IsCompleted",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                    Ingeboekt
                </button>
            )
        },
        cell: (object, _unused) => {
            const {IsCompleted, FormId} = object.row.original
            return (
                <IsCompletedButton isCompleted={IsCompleted} formId={FormId} />
            )
        },
    },
]
