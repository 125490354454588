import React from 'react'
import Loading from '../../assets/loading.gif'

function LoadingIcon({style, text=''}) {
    return (
        <div className={style}>
            <img src={Loading} alt='loading' className='h-6'></img>
            {text && <p>{text}</p>}
        </div>
    )
}

export default LoadingIcon