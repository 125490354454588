import Card from "../../components/layout/Card"

function Home({currentUser}) {
    return (
        <Card header='Welkom'>
            <p>Hallo {currentUser.username}, welkom op het TUMFUR Admin Portaal !</p>
        </Card>
    )
}

export default Home