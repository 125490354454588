import React from 'react'

function Button({text, onClick, disabled, styles}) {
    return (
        <button 
            onClick={onClick}
            disabled={disabled}
            className={`bg-tumfur-green text-white rounded-md ${styles}`}
        >
            {text}
        </button>
    )
}

export default Button