const headerBodyData = {
    items: [
        {
            name: 'Naar de website',
            href: 'https://tumfur.nl'
        },
        {
            name: 'Home',
            href: '/'
        },
        {
            name: 'Uitloggen',
            href: '#',
            onClick: 'signOut'
        },
    ]
}

export default headerBodyData