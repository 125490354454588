import React, { useState } from 'react'
import Button from '../../../components/layout/Button'
import {api} from '../../../lib/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRight, faArrowRightLong, faCheck, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons'
import LoadingIcon from '../../../components/layout/LoadingIcon'

function Upload() {
    const [fileToUpload, setFileToUpload] = useState()
    const [validationData, setValidationData] = useState()
    const [uploading, setUploading] = useState(false)
    const [uploadingBoekhouden, setUploadingBoekhouden] = useState(false)
    const [uploadComplete, setUploadComplete] = useState(false)
    const [boekhoudenComplete, setBoekhoudenComplete] = useState(false)
    const [responseData, setResponseData] = useState('')
    const handleUpload = async () => {
        setUploading(true)
        const res = await fetch(fileToUpload)
        // const file = await convertToBase64(fileToUpload)
        const blob = res.blob()
        const formData = new FormData()
        formData.append('upload', fileToUpload)
        formData.append('fileName', fileToUpload.name)
        if(blob) {
            api.postBoekhouden('/boekhouden/upload/validate', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                setValidationData(res.data.result)
                api.post('/boekhouden/excel/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then(res => {
                    if(!res.data.success) {
                        setUploadComplete(false)
                        console.log(res.data.result)
                        return console.log('ERROR while uploading file ... ')
                    }
                    setUploading(false)
                    setUploadComplete(true)
                })
            })
        }
    }
    const handleSendToEboekhouden = async () => {
        setUploadingBoekhouden(true)
        const formData = new FormData()
        formData.append('upload', fileToUpload)
        api.postBoekhouden(
            '/boekhouden/upload/eboekhouden',
            formData,
            {headers: {'Content-Type': 'multipart/form-data'}}
        ).then(res => {
            console.log(res)
            setUploadingBoekhouden(false)
            setBoekhoudenComplete(true)
        })
    }
    return (
        <div>
            <div className='flex items-center'>
                <input 
                    type='file'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' 
                    id='upload' 
                    name='upload' 
                    onChange={file => setFileToUpload(file.target.files[0])} 
                    className={`border-2 border-tumfur-green py-4 px-2 rounded-md shadow-lg ${fileToUpload ? 'bg-tumfur-green text-white opacity-70' : ''}`}
                />
                <FontAwesomeIcon icon={faArrowRightLong} className={`w-20 ${fileToUpload ? 'text-tumfur-green': ''}`} />
                <Button 
                    text={uploadComplete ? <FontAwesomeIcon icon={faCheck} className='border-2 border-white rounded-full aspect-square p-1 my-1' /> : (uploading ? <LoadingIcon style='bg-white rounded-md p-1'/> : '1) Upload')} 
                    onClick={handleUpload} 
                    disabled={!fileToUpload || uploadComplete}
                    styles={`h-20 w-40 px-2 shadow-lg flex items-center justify-center ${!fileToUpload || uploadComplete ? 'opacity-70' : ''}`}
                />
                <FontAwesomeIcon icon={faArrowRightLong} className={`w-20 ${uploadComplete ? 'text-tumfur-green': ''}`} />
                <Button 
                    text={boekhoudenComplete ? <FontAwesomeIcon icon={faCheck} className='border-2 border-white rounded-full aspect-square p-1 my-1' /> : (uploadingBoekhouden ? <LoadingIcon style='bg-white rounded-md p-1' /> : '2) Doorsturen naar e-Boekhouden')} 
                    disabled={!uploadComplete} 
                    onClick={handleSendToEboekhouden}
                    styles={`h-20 w-40 px-2 shadow-lg flex items-center justify-center ${!uploadComplete ? 'opacity-70' : ''}`}
                />
            </div>
            {validationData && (
                <div className='flex flex-row gap-8'>
                    <Validations title='Goed' data={validationData.successes}/>
                    <Validations title='Fout' data={validationData.forReview}/>
                </div>
            )}
        </div>
    )
}

function Validations({title, data}) {
    return (
        <div className=''>
            <p className='italic underline underline-offset-2'>{title}</p>
            <ul>
                {data.map(result => {
                    return (
                        <li>{result}</li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Upload