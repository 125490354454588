import React, { useState } from 'react'
import headerBodyData from './body-data/headerBodyData'
import { useNavigate } from 'react-router-dom'

function HeaderDropdown({signOut}) {
    const [active, setActive] = useState(false)
    const MENU_ITEMS = headerBodyData.items

    const navigate = useNavigate()

    const handleSignOut = () => {
        signOut()
        navigate('/login')
    }

    return (
        <div className='relative right-8 text-white'>
            <button className={`bg-tumfur-green w-12 h-12 mx-auto border-2 border-black ${!active ? 'rounded-full' : 'rounded-sm border-b-0'}`} onClick={() => setActive(!active)}>{!active ? '+' : '-'}</button>
            {
                active && (
                    <div className={`absolute w-40 bg-tumfur-green text-center border-2 border-black p-2 right-0 shadow-md ${active ? 'border-t-0' : ''}`}>
                        <ul className='flex flex-col'>
                            {MENU_ITEMS.map((menuItem, index) => {
                                if(menuItem.onClick) { // Only for sign out button
                                    return (
                                        <li className='shadow-sm mb-2 mt-6' key={index}>
                                            <button className='underline underline-offset-2' onClick={handleSignOut}><a>{menuItem.name}</a></button>
                                        </li>
                                    )
                                }
                                return (
                                    <li className='shadow-sm my-2' key={index}>
                                        <button>
                                            <a className='underline underline-offset-2' href={menuItem.href}>{menuItem.name}</a>
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )
            }
        </div>
    )
}

export default HeaderDropdown
