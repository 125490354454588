import React from 'react'
import { dashboardColumns } from './utils/dashboardColumns'
import { DataTable } from '../../../components/data/datatable/DataTable'

function Formulieren({data}) {
    return (
        <div>
            <DataTable columns={dashboardColumns} data={data} classNames={''} searchKey='Name' />
        </div>
    )
}

export default Formulieren