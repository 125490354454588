import { useEffect } from "react";

import { Authenticator, useAuthenticator, View, useTheme, Text, Image, Heading, Button } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';

export function Login() {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || '/';

    const components = {
        Header() {
            const { tokens } = useTheme();
        
            return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image
                alt="Amplify logo"
                src="https://docs.amplify.aws/assets/logo-dark.svg"
                />
            </View>
            );
        },
        
        Footer() {
            const { tokens } = useTheme();
        
            return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                &copy; All Rights Reserved
                </Text>
            </View>
            );
        },
        
        SignIn: {
            Header() {
            const { tokens } = useTheme();
        
            return (
                <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
                >
                Sign in to your account
                </Heading>
            );
            },
            Footer() {
            const { toResetPassword } = useAuthenticator();
        
            return (
                <View textAlign="center">
                <Button
                    fontWeight="normal"
                    onClick={toResetPassword}
                    size="small"
                    variation="link"
                >
                    Reset Password
                </Button>
                </View>
            );
            },
        },
        ConfirmSignIn: {
            Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
                >
                Enter Information:
                </Heading>
            );
            },
            Footer() {
            return <Text>Footer Information</Text>;
            },
        },
        ResetPassword: {
            Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
                >
                Enter Information:
                </Heading>
            );
            },
            Footer() {
            return <Text>Footer Information</Text>;
            },
        },
        ConfirmResetPassword: {
            Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
                >
                Enter Information:
                </Heading>
            );
            },
            Footer() {
            return <Text>Footer Information</Text>;
            },
        },
    };
      
    const formFields = {
        signIn: {
            username: {
            placeholder: 'Enter your email',
            },
        },
        forceNewPassword: {
            password: {
            placeholder: 'Enter your Password:',
            },
        },
        resetPassword: {
            username: {
            placeholder: 'Enter your email:',
            },
        },
        confirmResetPassword: {
            confirmation_code: {
            placeholder: 'Enter your Confirmation Code:',
            label: 'New Label',
            isRequired: false,
            },
            confirm_password: {
            placeholder: 'Enter your Password Please:',
            },
        },
        confirmSignIn: {
            confirmation_code: {
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
            },
        },
    };

    useEffect(() => {
        if (route === 'authenticated') {
        navigate(from, { replace: true });
        }
    }, [route, navigate, from]);

    return (
        <View className='flex justify-center mt-2'>
            <Authenticator formFields={formFields} components={components}></Authenticator>
        </View>
    );
}