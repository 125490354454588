import { Outlet } from 'react-router-dom'
import Header from './Header'
import { useAuthenticator } from '@aws-amplify/ui-react'
import NotAuthenticated from '../auth/NotAuthenticated'

function Layout({ signOut }) {
    const {route} = useAuthenticator((context) => [context.route])
    return (
        <div className='w-full max-w-[100vw] min-h-[100vh] flex flex-col bg-gray-200'>
            <Header signOut={signOut}/>
            <div className='w-full h-full py-10 px-8'>
                {route === 'authenticated' ? <Outlet /> : <NotAuthenticated />}
            </div>
        </div>
    )
}

export default Layout