import React from 'react'

function Menu({children}) {
    return (
        <div className='flex flex-row'>
            {children}
        </div>
    )
}

export default Menu