import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Card from '../../../components/layout/Card'
import Menu from '../../../components/layout/Menu'
import MenuItem from '../../../components/layout/MenuItem'
import Algemeen from './Algemeen'
import Formulieren from './Formulieren'
import Producten from './Producten'
import Varianten from './Varianten'

const PROD_API_URL = 'https://s51vi4jzw8.execute-api.us-east-1.amazonaws.com'

function Aanmeldformulier() {
    const [selected, setSelected] = useState('algemeen')
    const [allData, setAllData] = useState()
    const [fetchingData, setFetchingData] = useState(false)

    useEffect(() => {
        setFetchingData(true)
        axios.get(`${PROD_API_URL}/aanmeldformulier/forms/all`)
            .then(result => {
                setAllData(result.data.data.recordset.sort((a,b) => b.FormId - a.FormId))
                setFetchingData(false)
            })
    }, [])


    const leftExtraStyle = 'rounded-tl-md '.concat(selected === 'algemeen' ? 'bg-tumfur-green/70' : 'bg-gray-100')
    const rightExtraStyle = 'rounded-tr-md '.concat(selected === 'mijn-varianten' ? 'bg-tumfur-green/70' : 'bg-gray-100')
    return (
        <>
            <Menu>
                <MenuItem name='Algemeen' extraStyle={leftExtraStyle} onClick={() => setSelected('algemeen')}/>
                <MenuItem name='Mijn formulieren' extraStyle={selected === 'mijn-formulieren' ? 'bg-tumfur-green/70' : 'bg-gray-100'} onClick={() => setSelected('mijn-formulieren')}/>
                <MenuItem name='Mijn producten' extraStyle={selected === 'mijn-producten' ? 'bg-tumfur-green/70' : 'bg-gray-100'} onClick={() => setSelected('mijn-producten')}/>
                <MenuItem name='Mijn varianten' extraStyle={rightExtraStyle} onClick={() => setSelected('mijn-varianten')}/>
            </Menu>
            <Card header='Aanmeldformulier' extraStyle='rounded-tl-none'>
                {selected === 'algemeen' && <Algemeen data={allData} fetchingData={fetchingData} />}
                {selected === 'mijn-formulieren' && <Formulieren data={allData} />}
                {selected === 'mijn-producten' && <Producten data={allData} />}
                {selected === 'mijn-varianten' && <Varianten data={allData} />}
            </Card>
        </>
    )
}

export default Aanmeldformulier
