import axios from 'axios'

export const api = {
    get: async (endpoint) => {
        return await axios.get(process.env.REACT_APP_API_URL + endpoint)
    },
    post: async (endpoint, body, config={}) => {
        console.log(process.env.REACT_APP_API_URL + endpoint)
        return await axios.post(process.env.REACT_APP_API_URL + endpoint, body, config)
    },
    postBoekhouden: async (endpoint, body, config={}) => {
        console.log(process.env.REACT_APP_BOEKHOUDEN_API_URL + endpoint)
        return await axios.post(process.env.REACT_APP_BOEKHOUDEN_API_URL + endpoint, body, config)
    },
}
