import React, { useState } from 'react'
import Menu from '../../../components/layout/Menu'
import MenuItem from '../../../components/layout/MenuItem'
import Card from '../../../components/layout/Card'
import Upload from './Upload'

function Boekhouden() {
    const [selected, setSelected] = useState('upload')
    const extraStyle = 'rounded-t-md '.concat(selected === 'algemeen' ? 'bg-tumfur-green/70' : 'bg-gray-100')
    return (
        <>
        <Menu>
            <MenuItem name='Boekhouden' extraStyle={extraStyle} onClick={() => setSelected('upload')}/>
        </Menu>
        <Card header='Bestand uploaden' extraStyle='rounded-tl-none'>
            {selected === 'upload' && <Upload />}
        </Card>
        </>
    )
}

export default Boekhouden