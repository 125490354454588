import React, {useState} from 'react'
import LoadingIcon from '../../layout/LoadingIcon'
import axios from 'axios'

function DownloadListingButton({seller}) {
    const [updatingStatus, setUpdatingStatus] = useState(false)
    const PROD_API_URL = 'https://s51vi4jzw8.execute-api.us-east-1.amazonaws.com'

    const handleCompleteSave = async () => {
        try {
            setUpdatingStatus(true)
            const res = axios.get(PROD_API_URL + `/listings/${seller.toLowerCase().replace(/ /g, '_')}`).then((res) => {
                console.log(res)
                setUpdatingStatus(false)
                window.location.reload()
                // return res
            })
        } catch(err) {
            console.log(err)
        }
    }
    return (
        <div className='flex w-full'>
            {
                updatingStatus ?
                    <LoadingIcon />
                    : 
                    <button
                        className={`h-full rounded-lg bg-tumfur-green text-white px-2 border-2 border-tumfur-main shadow-lg hover:opacity-80`}
                        onClick={handleCompleteSave}>
                        Download listing data
                    </button>
            }
        </div>      
    )
}

export default DownloadListingButton