import DownloadAanmeldformulier from "../../../../components/data/aanmeldformulier/DownloadAanmeldformulier"
import IsCompletedButton from "../../../../components/data/aanmeldformulier/IsCompletedButton"
import DownloadListingButton from "../../../../components/data/listing/DownloadListingButton"

export const dashboardColumns= [
    {
        accessorKey: "ListingId",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Listing ID
                </button>
            )
        },
    },
    {
        accessorKey: "ClientName",
        header: ({ column }) => {
            return (
                <button
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Klant Naam
                </button>
            )
        },
    },
    {
        accessorKey: "ClientId",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Klant ID
                </button>
            )
        },
    },
    {
        accessorKey: "CreateDate",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Datum
                </button>
            )
        },
    },
    {
        accessorKey: "DownloadData",
        header: ({ column }) => {
            return (
                <button
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                    Download listing data
                </button>
            )
        },
        cell: (object, _unused) => {
            const {ClientName} = object.row.original
            return (
                <DownloadListingButton seller={ClientName} />
            )
        },
    },
]
