"use client"

import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table"
import { useEffect, useState } from "react"
import Button from "../../layout/Button"

export function DataTable({ columns, data, classNames, searchKey }) {
    const [columnFilters, setColumnFilters] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedPageSize, setSelectedPageSize] = useState(5)
    const [sorting, setSorting] = useState([])

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel({pageSize: 5}),
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
            columnFilters
        },
    })

    useEffect(() => {
        table.setPageSize(selectedPageSize)
    }, [selectedPageSize])

    const handlePageSizeChange = (pageSize) => {
        setSelectedPageSize(pageSize)
    }

    return (
        <div>
            <div>
            <input
                placeholder="Zoek klant..."
                value={(table.getColumn(searchKey)?.getFilterValue()) ?? ""}
                onChange={(event) =>
                    table.getColumn(searchKey)?.setFilterValue(event.target.value)
                }
                className="max-w-sm my-4 border-2 border-blue-400 rounded-lg px-4 py-2"
            />
            </div>
            <div className="rounded-md border">
                <Table className={classNames.table}>
                    <TableHeader className={classNames.tableHeader}>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id} className={classNames.tableRow}>
                        {headerGroup.headers.map((header) => {
                            return (
                            <TableHead key={header.id} className={classNames.tableHead}>
                                {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                    )}
                            </TableHead>
                            )
                        })}
                        </TableRow>
                    ))}
                    </TableHeader>
                    <TableBody className={classNames.tableBody}>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                        <TableRow
                            className={classNames.tableRow}
                            key={row.id}
                            data-state={row.getIsSelected() && "selected"}
                        >
                            {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id} className={classNames.tableCell}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                            ))}
                        </TableRow>
                        ))
                    ) : (
                        <TableRow className={classNames.tableRow}>
                        <TableCell colSpan={columns.length} className="h-24 text-center">
                            No results.
                        </TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
            </div>
            <div className='flex w-full my-4 justify-between'>
                <span className='w-[30vw] flex items-center gap-6'>
                    <Button 
                        text='Previous' 
                        onClick={() => {
                            if(table.getCanPreviousPage()) {
                                table.previousPage()
                                setCurrentPage(page => page - 1)
                            }
                        }}
                        styles={`w-32 rounded-full bg-blue-400 text-white ${!table.getCanPreviousPage() ? 'opacity-70' : ''}`} 
                        disabled={!table.getCanPreviousPage()} 
                    />
                    <p>{currentPage} of {table.getPageCount()}</p>
                    <Button 
                        text='Next' 
                        onClick={() => {
                            if(table.getCanNextPage()) {
                                table.nextPage()
                                setCurrentPage(page => page + 1)
                            }
                        }}
                        styles={`w-32 rounded-full ${!table.getCanNextPage() ? 'opacity-70' : ''}`} 
                        disabled={!table.getCanNextPage()} 
                    />
                </span>
                <span className='flex flex-row'>
                    <p className='mr-2'>Rows per page</p>
                    <select 
                        className='w-fit rounded-xl px-4 border-2 border-blue-400'
                        value={selectedPageSize} 
                        onChange={e => handlePageSizeChange(e.target.value)}>
                        {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={`${pageSize}`}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </span>
            </div>
        </div>
    )
}
