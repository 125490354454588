import React, {useEffect, useState} from 'react'
import Card from '../../../components/layout/Card'
import Menu from '../../../components/layout/Menu'
import MenuItem from '../../../components/layout/MenuItem'
import Algemeen from './Algemeen'
import Listings from './Listings'
import axios from 'axios'

const PROD_API_URL = 'https://s51vi4jzw8.execute-api.us-east-1.amazonaws.com'

function Listing() {
    const [selected, setSelected] = useState('algemeen')
    const [allData, setAllData] = useState()
    const [fetchingData, setFetchingData] = useState(false)
    const [fetchError, setFetchError] = useState(false)

    useEffect(() => {
        try {
            setFetchingData(true)
            const res = axios.get(`${PROD_API_URL}/listings/all`)
                .then(result => {
                    setAllData(result.data.data.recordset.sort((a,b) => b.ListingId - a.ListingId))
                    setFetchingData(false)
                })
        } catch(err) {
            setFetchingData(false)
            setFetchError(true)
        }
    }, [])

    const leftExtraStyle = 'rounded-tl-md '.concat(selected === 'algemeen' ? 'bg-tumfur-green/70' : 'bg-gray-100')
    const rightExtraStyle = 'rounded-tr-md '.concat(selected === 'mijn-listings' ? 'bg-tumfur-green/70' : 'bg-gray-100')
    return (
        <>
            <Menu>
                <MenuItem name='Algemeen' extraStyle={leftExtraStyle} onClick={() => setSelected('algemeen')}/>
                <MenuItem name='Mijn Listings' extraStyle={rightExtraStyle} onClick={() => setSelected('mijn-listings')}/>
            </Menu>
            <Card header='Listings' extraStyle='rounded-tl-none'>
                {selected === 'algemeen' && <Algemeen data={allData} fetchingData={fetchingData} fetchError={fetchError}/>}
                {selected === 'mijn-listings' && <Listings data={allData} />}
            </Card>
        </>
    )
}

export default Listing
