const menuConfig = {
    menuItems: {
        data: {
            title: 'Data',
            subItems: [
                {title: 'Aanmeldformulier data', url: '/data/aanmeldformulier'},
                {title: 'Listing data', url: '/data/listing'},
            ]
        },
        administratie: {
            title: 'Administratie',
            subItems: [
                {title: 'Boekhouden', url: '/administratie/boekhouden'},
            ]
        },
    }
}

export default menuConfig