import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  Authenticator
} from "@aws-amplify/ui-react";

import Layout from './components/layout/Layout'
import Home from './pages/home/Home'
import Aanmeldformulier from "./pages/data/aanmeldformulier/Aanmeldformulier";
import Listing from "./pages/data/listing/Listing";
import { RequireAuth } from "./components/auth/RequireAuth";
import { Login } from "./components/auth/Login";
import { AuthConfig } from "./AuthConfig";
import Boekhouden from "./pages/administratie/boekhouden/Boekhouden";

function App() {
  return (
    <Authenticator hideSignUp={true} formFields={AuthConfig.formFields} components={AuthConfig.components} className="bg-tumfur-main w-[100vw] h-[100vh]">
        {({signOut, user}) => (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<RequireAuth><Layout signOut={signOut}/></RequireAuth>}>
                        <Route index element={<Home currentUser={user}/>} />
                        <Route path='data'>
                            <Route path='aanmeldformulier' element={<Aanmeldformulier/>}/>
                            <Route path='listing' element={<Listing />}/>
                        </Route>
                        <Route path='administratie'>
                            <Route path='boekhouden' element={<Boekhouden/>}/>
                        </Route>
                    </Route>
                    <Route path='/login' element={<Login />}/>
                </Routes>
            </BrowserRouter>
        )}
    </Authenticator>
  );
}

export default App;